import React from 'react';
import Logo from './verticalNavigation/Logo';
import { Avatar, DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import { faBarsSort } from '@fortawesome/pro-regular-svg-icons/faBarsSort';
import { faX } from '@fortawesome/pro-regular-svg-icons/faX';
import InAppNotificationsIcon from 'components/in-app-notifications/Icon';
import { animated, useSpring } from '@react-spring/web';
import { NavigationItem } from './utils/libs';
import Link from 'components/shared/Link';
import UserPermissionGate from 'components/UserPermissionGate';
import routes, { modalRoutes } from 'constants/routes';
import { Module } from 'config';
import VerticalNavigationDropdownOption from './verticalNavigation/userActionsMenu/UserActionDropdownOption';
import { useTranslation } from 'react-i18next';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faGift } from '@fortawesome/pro-regular-svg-icons/faGift';
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons/faPowerOff';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import IntercomUtils from 'app/utils/intercom';
import { UserPermission } from 'constants/userPermission';
import { faGear } from '@fortawesome/pro-regular-svg-icons/faGear';
import { User } from 'types/User';
import PublicUtils from 'components/publicPreviews/publicUtils';
import { NotificationCount } from 'types/InAppNotification';
import { MobileNavigationMenu } from './mobileNavigation/MobileNavigationMenu';

type Props = {
  menuItems: NavigationItem[];
  user: Pick<User, 'role' | 'avatar' | 'name'>;
  activeItem?: NavigationItem;
  isPublic?: boolean;
  logoLinkProps?: Record<string, any>;
  notificationsData?: NotificationCount;
  logoutUser?: () => void;
  isSubscription: boolean;
};

export const MobileNavigation = (props: Props) => {
  const { isPublic, user, notificationsData, logoutUser, isSubscription } =
    props;

  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  const { t } = useTranslation();

  const animationProps = useSpring({
    transform: isMobileNavOpen ? 'translateX(0%)' : 'translateX(-100%)',
    opacity: isMobileNavOpen ? 1 : 0,
  });

  return (
    <>
      <div className="tw-flex tw-h-[56px] tw-w-full tw-items-center tw-justify-between tw-border-b-[1px] tw-border-solid tw-border-grayscale-5 tw-bg-white tw-px-4 tw-py-1 sm:tw-hidden">
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
          <div className="sm:tw-hidden">
            <IconButton
              variant="ghost"
              className="tw-h-[30px] tw-w-[30px]"
              icon={<Icon icon={!isMobileNavOpen ? faBarsSort : faX} />}
              size="small"
              onClick={() => {
                setIsMobileNavOpen(!isMobileNavOpen);
              }}
            />
          </div>
          <div>
            <Logo
              linkProps={
                isPublic
                  ? {
                      'data-name': 'public-link-logo',
                      isRouterLink: false,
                      to: 'https://www.kontentino.com/',
                    }
                  : {
                      'data-name': 'mobile-navigation-logo',
                    }
              }
              className={'tw-flex-shrink-0 !tw-p-0'}
              data-cy="navigation-link-default"
            />
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
          {!props.isPublic && (
            <Link
              to={routes.IN_APP_NOTIFICATIONS}
              data-name="navbar_notifications"
              isRouterLink
              onClick={() => setIsMobileNavOpen(false)}
            >
              <InAppNotificationsIcon
                notificationsCount={notificationsData?.count ?? 0}
              />
            </Link>
          )}
          {props.isPublic ? (
            <Link
              to={{
                hash: modalRoutes.PUBLIC_INVITE_USER,
              }}
            >
              <Avatar
                src={undefined}
                size={30}
                name={PublicUtils.publicMock.user.name}
              />
            </Link>
          ) : (
            <DropdownMenu.Root>
              <DropdownMenu.Trigger
                className="hover:tw-text-primary-100"
                data-name="navbar_avatar"
              >
                <span className="tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-text-inherit tw-transition-all tw-duration-100 tw-ease-in-out">
                  <Avatar
                    src={user.avatar?.src}
                    size={30}
                    name={user.name}
                    className="tw-border-grayscale-20 tw-transition-all tw-duration-100 tw-ease-in-out hover:tw-border-2 hover:tw-border-primary-20"
                  />
                </span>
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <DropdownMenu.Content
                  className="tw-z-[99999] tw-w-[240px]"
                  side="bottom"
                  sideOffset={10}
                >
                  <Link to={Module.OldApp.URL.MY_PROFILE}>
                    <DropdownMenu.Item data-name="navbar_avatar_personal">
                      <VerticalNavigationDropdownOption
                        icon={<Icon icon={faUser} />}
                        label={t('navigation.profile.myAccount')}
                      />
                    </DropdownMenu.Item>
                  </Link>

                  <Link to={Module.OldApp.URL.NOTIFICATIONS_SETTINGS}>
                    <DropdownMenu.Item data-name="navbar_avatar_notifications">
                      <VerticalNavigationDropdownOption
                        icon={<Icon icon={faBell} />}
                        label={t('navigation.profile.notificationSettings')}
                      />
                    </DropdownMenu.Item>
                  </Link>
                  {isSubscription && (
                    <Link to={{ hash: modalRoutes.REFERRAL_PROGRAM }}>
                      <DropdownMenu.Item data-name="navbar_avatar_referral-program">
                        <VerticalNavigationDropdownOption
                          icon={<Icon icon={faGift} />}
                          label={t('navigation.profile.referralProgram')}
                        />
                      </DropdownMenu.Item>
                    </Link>
                  )}

                  <UserPermissionGate
                    scopes={UserPermission.ACCESS_COMPANY_SETTINGS}
                  >
                    <Link to={Module.OldApp.URL.SETTINGS.COMPANY_SETTINGS}>
                      <DropdownMenu.Item data-name="navbar_settings_company">
                        <VerticalNavigationDropdownOption
                          icon={<Icon icon={faGear} />}
                          label={t('navigation.userSubActions.general')}
                        />
                      </DropdownMenu.Item>
                    </Link>
                  </UserPermissionGate>
                  <DropdownMenu.Item
                    data-name="navbar_help-center_help"
                    onSelect={() =>
                      IntercomUtils.newConversation(
                        'Hello! Just having a question',
                      )
                    }
                  >
                    <VerticalNavigationDropdownOption
                      icon={<Icon icon={faComment} />}
                      label={t('navigation.userSubActions.getHelp')}
                    />
                  </DropdownMenu.Item>

                  <DropdownMenu.Separator />
                  <DropdownMenu.Item
                    data-name="navbar_avatar_logout"
                    onSelect={logoutUser}
                  >
                    <VerticalNavigationDropdownOption
                      icon={<Icon icon={faPowerOff} />}
                      label={t('navigation.profile.logout')}
                    />
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          )}
        </div>
      </div>

      <animated.div
        style={animationProps}
        className="tw-absolute tw-inset-y-0 tw-left-0 tw-z-[99999] tw-mt-[56px] tw-flex tw-h-full tw-w-full tw-items-center tw-overflow-y-auto tw-bg-white tw-py-8"
      >
        <MobileNavigationMenu
          isPublic={isPublic}
          menuItems={props.menuItems}
          activeMenuItem={props.activeItem}
          onClose={() => setIsMobileNavOpen(false)}
        />
      </animated.div>
    </>
  );
};
