import React from 'react';
import clsx from 'clsx';
import { NavigationItem } from '../utils/libs';
import { Icon } from '@kontentino/ui';

interface MenuItemProps {
  item: NavigationItem;
  activeMenuItem?: NavigationItem;
}

const MobileNavigationMenuItem: React.FC<MenuItemProps> = ({
  item,
  activeMenuItem,
}) => (
  <span
    className={clsx('tw-flex tw-items-center tw-gap-2 tw-py-4 tw-pl-5', {
      'tw-bg-grayscale-5 tw-text-primary-100': activeMenuItem?.key === item.key,
    })}
  >
    {item.icon && <Icon icon={item.icon} size="lg" />}
    <span>{item.label}</span>
  </span>
);

export default MobileNavigationMenuItem;
