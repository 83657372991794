import React, { FC } from 'react';
import { preventDefault } from 'utils/mouseEvent';
import { NavigationItem } from '../utils/libs';
import { useModalHistory } from 'context/ModalRouter';
import { modalRoutes } from 'constants/routes';
import UserPermissionGate from 'components/UserPermissionGate';
import Link from 'components/shared/Link';
import { getNavigationItemUrl } from '../verticalNavigation/mainMenu/MenuItems';
import MobileNavigationMenuItem from './MobileNavigationMenuItem';

type Props = {
  isPublic?: boolean;
  menuItems: NavigationItem[];
  activeMenuItem?: NavigationItem;
  onClose: () => void;
};

type ItemProps = {
  item: NavigationItem;
  onClose: () => void;
  activeMenuItem?: NavigationItem;
};

const PublicMenuItem: FC<ItemProps> = ({ item, onClose, activeMenuItem }) => {
  const history = useModalHistory();

  return (
    <div
      className="tw-flex tw-flex-col tw-gap-y-4"
      onClick={preventDefault(() => {
        onClose();
        history.push({
          hash: modalRoutes.PUBLIC_INVITE_USER,
          state: { actionType: 'navbar' },
        });
      })}
    >
      <MobileNavigationMenuItem item={item} activeMenuItem={activeMenuItem} />
    </div>
  );
};

const AppMenuItem: FC<ItemProps> = ({ item, onClose, activeMenuItem }) => {
  return (
    <UserPermissionGate scopes={item.permissionsScope} exact={false}>
      <Link
        onClick={(e) => {
          onClose();
          if (!(item.url || item.routerUrl)) {
            e.preventDefault();
          }
        }}
        isRouterLink={!!item.routerUrl}
        to={getNavigationItemUrl(item)}
        className="tw-w-full"
        data-name={`navigation-link-${item.key}`}
      >
        <MobileNavigationMenuItem item={item} activeMenuItem={activeMenuItem} />
      </Link>
    </UserPermissionGate>
  );
};

export const MobileNavigationMenu: FC<Props> = ({
  isPublic,
  menuItems,
  activeMenuItem,
  onClose,
}) => {
  return (
    <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-start tw-justify-start">
      {menuItems
        .filter((item) => !item.isDisabled)
        .map((item, index) => {
          if (isPublic) {
            return (
              <PublicMenuItem
                key={index}
                item={item}
                onClose={onClose}
                activeMenuItem={activeMenuItem}
              />
            );
          }

          return (
            <AppMenuItem
              key={index}
              item={item}
              onClose={onClose}
              activeMenuItem={activeMenuItem}
            />
          );
        })}
    </div>
  );
};
